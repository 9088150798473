import {
    goodsSkuDetail,
    goodsDetail,
    manjian,
    aftersale,
    modifyClicks,
    addGoodsbrowse,
    goodsSkuInfo,
    goodsQrcode,
    evaluateConfig,
    goodsSeemList,
    goodsSeemGrandList
} from "@/api/goods/goods"
import {
    isCollect,
    addCollect,
    deleteCollect
} from "@/api/goods/goods_collect"
import {
    couponTypeList,
    couponReceive,
    goodsCoupon
} from "@/api/coupon"
import {
    bundlingList
} from "@/api/bundling"
import {
    goodsEvaluateList
} from "@/api/goods/evaluate"
import {
    mapGetters
} from "vuex"
import CountDown from "vue2-countdown"
import {
    getArea
} from "@/api/address"
import {
    shopServiceOpen
} from "@/api/website.js"
import { refundList } from "@/api/order/refund"


export default {
    data() {
        return {
            // 表头属性
            goodsAttrFormat: [],
            // 展示规格信息
            skuData: [],
            goods: {},
            tabCheck: ['产品详情', '同系列推荐', '同品牌推荐'],
            tabCheckValue: '产品详情',
            test: [require('img/test.jpg'), require('img/test.jpg'), require('img/test.jpg')],
            test2: [
                { code: '1231231', color: '红色', stock: 111 },
                { code: '1231231', color: '红色', stock: 111 },
                { code: '1231231', color: '红色', stock: 111 }
            ],
            skuId: 0,
            loading: true,
            showIndex: 0,

            picImages: [],
            picZoomUrl: "",
            thumbPosition: 0,
            // 是否可以移动
            moveThumbLeft: false,
            moveThumbRight: false,

            // 商品详情
            goodsSkuDetail: {
                video_url: ""
            },
            evaluate_show: false, //评论是否显示
            discountText: "距离结束仅剩",
            discountTimeMachine: {
                currentTime: 0,
                startTime: 0,
                endTime: 0
            },
            qrcode: "",
            specDisabled: false,
            specBtnRepeat: false, //防止重复
            btnSwitch: false,

            // 店铺详情
            shopInfo: {},
            whetherCollection: 0,
            score: 0,

            couponList: [], //优惠券列表
            couponBtnRepeat: false, //获取优惠券防止重复提交

            manjian: {}, //满减活动列表

            //评价
            currentPage: 1,
            pageSize: 10,
            total: 0,
            goodsEvaluateList: [],
            //组合套餐
            bundling: [{
                bundling_goods: {
                    bl_name: "",
                    sku_image: ""
                }
            }],
            service: null,

            number: 1,
            tabName: "detail",
            tabBundling: "",
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4", // 类型
                    src: "" // url地址
                }],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: true, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            },
            switchMedia: "img",

            // 是否关注店铺
            hasFollow: false,

            // 客服配置
            kefuConfig: {
                system: '',
                open_pc: '',
                open_url: ''
            },

            // 省市区县
            provinceArr: {},
            cityArr: {},
            districtArr: {},

            // 省市区县 id
            currTabAddres: "province",
            hideRegion: false,
            selectedAddress: {},
            service_list: [], //商品服务
            loadingAd: false,
            goods_attr_format:{}
        }
    },
    components: {
        CountDown
    },
    created() {
        this.goodsId = this.$route.params.pathMatch
        this.getGoodsDetail()
        this.getGoodsSeemGrandList()
        this.getGoodsSeemList()
    },
    computed: {
        ...mapGetters(["token", "siteInfo", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit",
            'locationRegion', 'member'
        ]),
        logined: function() {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    },
    watch: {
        $route: function(curr) {
            this.skuId = curr.params.pathMatch
            this.getGoodsDetail()
        }
    },
    methods: {
        // textHidden,
        // handlePlay(){
        //     this.$refs.movie.play();
        //     this.isplay = false;
        // },
        // handlePause(){
        //     this.$refs.movie.pause();
        //     this.isplay = true;
        // },
        checkLogin() {
            return this.token ? true : false
        },
        // 添加到购物车
        addCart() {
            if (!this.checkLogin()) return this.$message({
                message: "您还未登录",
                type: "warning"
            })
            if (!this.checkLogin()) {
                // this.$router.replace("/introduce")
                console.log(465, this.$router)
            }
            this.skuData.forEach(item => {
                // console.log(item.buyed_num > 0)
                if (item.buyed_num > 0 && this.joinOrder(item, 2)) {
                    console.log(item.buyed_num)
                    this.$set(item, 'buyed_num', 0)
                }
            })
            console.log('123' + this.skuData)
        },
        // 提交订单
        actionOrder(skuGoods) {
            let sku_lists = []
            this.skuData.forEach(item => {
                if (item.buyed_num > 0 && this.joinOrder(item, 1)) {
                    sku_lists.push({ sku_id: item.sku_id, num: item.buyed_num })
                }
            })
            console.log(sku_lists);
            if (sku_lists.length > 0) {
                var data = {
                    sku_lists: JSON.stringify(sku_lists)
                }
                this.$store.dispatch("order/setOrderCreateData", data)
                this.$router.push({
                    //path: "/payment?type=0"
                    path: "/member/cart"
                })
            }
        },
        //跳转登录页面
        goTo() {
            this.$router.push('/login')
        },
        numChange() {
            // console.log(this.skuData);
        },
        // 主图跳转至
        showToIndex(index) {
            this.showIndex = index
                // console.log(this.picImages);
            this.picZoomUrl = this.picImages[index]
        },
        shopServiceOpen() {
            shopServiceOpen().then((res) => {
                if (res.code == 0) {
                    this.kefuConfig = res.data;
                }
            })
        },
        service_link() {
            if (this.token) {
                this.$refs.servicerMessage.show();
            } else {
                this.$message({
                    message: "您还未登录",
                    type: "warning"
                })
            }
        },
        tabChange(tab, event) {},
        bundlingChange(tab, event) {},
        getGoodsSeemList() {
            goodsSeemList({
                    goods_id: this.goodsId
                })
                .then(res => {
                    // console.log(res.data, '12312123')
                    this.goodsSeemList = res.data
                })
        },
        getGoodsSeemGrandList() {
            goodsSeemGrandList({
                    goods_id: this.goodsId
                })
                .then(res => {
                    // console.log(res.data, '12312123')
                    this.goodsSeemGrandList = res.data
                })
        },
        getGoodsDetail() {
            goodsDetail({
                    goods_id: this.goodsId
                })
                .then(res => {
                    // console.log(res)
                    if (res.data == null) {
                        // console.log(res.data)
                        return this.$router.push("/")
                    }

                    const getSkuData = data => {
                            if (!data) return []
                            let array = []
                            data.forEach(item => {
                                this.$set(item, 'buyed_num', 0)
                                array.push(item)
                            })
                            return array
                        }
                        // 商品信息
                    this.goods = res.data
                        // 轮播
                    //this.picImages = res.data.sku_data[0].sku_images.split(",")
                    this.picImages = res.data.goods_image.split(",")
                        // 当前图
                    this.picZoomUrl = this.picImages[0]
                        // sku数据
                    this.skuData = getSkuData(res.data.sku_data)
                        // console.log(res.data.sku_data)
                        // 商品描述
                    if (res.data.goods_attr_format) {
                        // 商品属性
                        this.goods_attr_format = JSON.parse(res.data.goods_attr_format);
                    }
                })
                .then(res => {
                    if (this.token != "") {
                        this.getWhetherCollection()
                    }
                    this.modifyGoodsInfo()
                        // this.getEvaluateConfig()
                })
                .catch(res => {
                    this.loading = false
                    this.$router.push("/")
                })
        },
        changeThumbImg(tag) {
            if (this.goodsSkuDetail.sku_images.length < 4) return
            let page = this.goodsSkuDetail.sku_images.length % 4 // 可见数量4个
            let position = 88
            if (page == 0) page = this.goodsSkuDetail.sku_images.length - 4 // 可见数量4个
            else if (page != 0 && page != 1 && page < 2) return

            if (tag == "prev") {
                if (this.thumbPosition != 0 && Math.round(this.thumbPosition, 2) != position) {
                    this.thumbPosition += position
                        // this.moveThumbLeft = true;
                } else {
                    // this.moveThumbLeft = false;
                }
            } else if (tag == "next") {
                if (Math.round(this.thumbPosition, 2) != -Math.round(position * page, 2)) {
                    this.thumbPosition -= position
                        // this.moveThumbRight = true;
                } else {
                    // this.moveThumbRight = false;
                }
            }
        },
        //获取用户是否关注
        getWhetherCollection() {
            isCollect({
                sku_id: this.skuData[0].sku_id,
                goods_id: this.goodsId
            }).then(res => {
                this.whetherCollection = res.data
            })
        },
        editCollection() {
            //未关注添加关注
            if (this.whetherCollection == 0) {
                addCollect({
                        sku_id: this.skuData[0].sku_id,
                        goods_id: this.goodsId
                    }).then(res => {
                        var data = res.data
                        if (data > 0) {
                            this.$message('收藏成功')
                            this.whetherCollection = 1
                            this.getGoodsDetail()
                        }
                        // console.log(data)
                    })
                    .catch(err => {
                        if (err.message == '您尚未登录，请先进行登录') {
                            this.$router.push('/login');
                        }
                        this.btnSwitch = false
                    })
            } else {
                //已关注取消关注
                this.$message('收藏成功')
            }
        },
        //获取满减信息
        getManjian() {
            manjian({
                goods_id: this.goodsSkuDetail.goods_id,
                site_id: this.goodsSkuDetail.site_id
            }).then(res => {
                let data = res.data
                if (data) {
                    this.manjian = data;
                    let limit = data.type == 0 ? '元' : '件';
                    Object.keys(data.rule_json).forEach((key) => {
                        var item = data.rule_json[key];
                        if (item.coupon_data) {
                            for (var i = 0; i < item.coupon_data.length; i++) {
                                item.coupon_data[i].coupon_num = item.coupon_num[i]
                            }
                        }

                        // 满减
                        if (item.discount_money != undefined) {
                            if (this.manjian.manjian == undefined) {
                                this.manjian.manjian = '满' + item.limit + limit + '减' + item.discount_money + '元';
                            } else {
                                this.manjian.manjian += '；满' + item.limit + limit + '减' + item.discount_money + '元';
                            }
                        }
                        // 满送
                        if (item.point != undefined || item.coupon != undefined) {
                            let text = '';
                            if (item.point != undefined) {
                                text = '送' + item.point + '积分';
                            }
                            if (item.coupon != undefined && item.coupon_data != undefined) {
                                item.coupon_data.forEach((couponItem, couponIndex) => {
                                    if (couponItem.type == 'discount') {
                                        if (text == '') text = '送' + item.coupon_num[couponIndex] + '张' + parseFloat(couponItem.discount) + '折优惠券';
                                        else text += '、送' + item.coupon_num[couponIndex] + '张' + parseFloat(couponItem.discount) + '折优惠券';
                                    } else {
                                        if (text == '') text = '送' + item.coupon_num[couponIndex] + '张' + parseFloat(couponItem.money) + '元优惠券';
                                        else text += '、送' + item.coupon_num[couponIndex] + '张' + parseFloat(couponItem.money) + '元优惠券';
                                    }
                                })
                            }
                            if (this.manjian.mansong == undefined) {
                                this.manjian.mansong = '满' + item.limit + limit + text;
                            } else {
                                this.manjian.mansong += '；' + '满' + item.limit + limit + text;
                            }
                        }
                        // 包邮
                        if (item.free_shipping != undefined) {
                            if (this.manjian.free_shipping == undefined) {
                                this.manjian.free_shipping = '满' + item.limit + limit + '包邮';
                            } else {
                                this.manjian.free_shipping += '；满' + item.limit + limit + '包邮';
                            }
                        }
                    })
                }
            })
        },
        // 获取优惠券
        getCoupon() {
            goodsCoupon({
                goods_id: this.goodsSkuDetail.goods_id
            }).then(res => {
                let data = res.data
                if (data) {
                    this.couponList = []
                    for (let i = 0; i < data.length; i++) {
                        if (i > 4) break
                        this.couponList.push(data[i])
                    }
                }
            })
        },
        // 领取优惠券
        receiveCoupon(couponTypeId) {
            if (this.couponBtnRepeat) return
            this.couponBtnRepeat = true

            couponReceive({
                    coupon_type_id: couponTypeId,
                    site_id: this.goodsSkuDetail.site_id,
                    get_type: 2 //获取方式:1订单2.直接领取3.活动领取
                })
                .then(res => {
                    var data = res.data
                    let msg = res.message
                    if (res.code == 0) {
                        msg = "领取成功"
                    }
                    this.$message({
                        message: msg,
                        type: "success"
                    })
                    this.couponBtnRepeat = false
                })
                .catch(res => {
                    this.couponBtnRepeat = false
                })
        },
        // 组合套餐
        getBundling() {
            bundlingList({
                sku_id: this.skuId
            }).then(res => {
                if (res.data && res.data.length) {
                    this.bundling = res.data
                    this.tabBundling = "bundling_" + this.bundling[0].bl_id
                    for (var i = 0; i < this.bundling.length; i++) {
                        for (var j = 0; j < this.bundling[i].bundling_goods.length; j++) {
                            if (this.bundling[i].bundling_goods[j].sku_id == this.skuId) {
                                this.bundling[i].bundling_goods.splice(j, 1)
                            }
                        }
                    }
                }
            })
        },
        // 售后保障
        getAftersale() {
            aftersale({}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data.content
                    if (res.data.content) this.service = res.data.content
                }
            })
        },
        //更新商品信息
        modifyGoodsInfo() {
            // console.log(1);
            //更新商品点击量
            modifyClicks({
                goods_id: this.goodsId,
                sku_id: this.skuData[0].sku_id
            })

            //添加足迹
            addGoodsbrowse({
                goods_id: this.goodsId,
                sku_id: this.skuData[0].sku_id
            })
        },
        // 商品二维码
        getGoodsQrcode() {
            goodsQrcode({
                sku_id: this.skuId
            }).then(res => {
                let data = res.data
                if (data.path.h5.img) this.qrcode = img(data.path.h5.img)
            })
        },
        getEvaluateConfig() {
            evaluateConfig()
                .then(res => {
                    // console.log(res, 'getEvaluateConfig')
                    if (res.code == 0) {
                        var data = res.data;
                        this.evaluateConfig = data;
                        if (this.evaluateConfig.evaluate_show == 1) {
                            //商品评论
                            this.evaluate_show = true
                            this.getGoodsEvaluate();
                        }
                    }
                })
        },
        // 商品评价列表
        getGoodsEvaluate() {
            goodsEvaluateList({
                page: this.currentPage,
                page_size: this.pageSize,
                goods_id: this.goodsSkuDetail.goods_id
            }).then(res => {
                // console.log(res, '商品评价列表')
                let list = []
                let msg = res.message
                if (res.code == 0 && res.data) {
                    list = res.data.list
                    this.total = res.data.count
                }

                for (var i = 0; i < list.length; i++) {
                    // 1好评2中评3差评
                    if (list[i].explain_type == 1) {
                        list[i].star = 5
                    } else if (list[i].explain_type == 2) {
                        list[i].star = 3
                    } else if (list[i].explain_type == 3) {
                        list[i].star = 1
                    }
                    if (list[i].images) {
                        list[i].images = list[i].images.split(",")
                        list[i].imagesFormat = []
                        for (var k = 0; k < list[i].images.length; k++) {
                            list[i].imagesFormat.push(img(list[i].images[k]))
                        }
                    }

                    if (list[i].again_images) {
                        list[i].again_images = list[i].again_images.split(",")
                        list[i].againImagesFormat = []
                        for (var j = 0; j < list[i].again_images.length; j++) {
                            list[i].againImagesFormat.push(img(list[i].again_images[j]))
                        }
                    }
                    if (list[i].is_anonymous == 1) list[i].member_name = list[i].member_name.replace(list[i].member_name.substring(1,
                        list[i].member_name.length - 1), "***")
                }
                this.goodsEvaluateList = list
            })
        },
        // 图片加载失败
        imageErrorEvaluate(index) {
            this.goodsEvaluateList[index].member_headimg = this.defaultHeadImage
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsEvaluate()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsEvaluate()
        },
        changeSpec(skuId, spec_id) {
            if (this.specDisabled) return
            this.specBtnRepeat = false
            this.skuId = skuId
                // 清空选择
            for (var i = 0; i < this.goodsSkuDetail.goods_spec_format.length; i++) {
                var sku = this.goodsSkuDetail.goods_spec_format[i]
                for (var j = 0; j < sku.value.length; j++) {
                    // 排除当前点击的规格值
                    if (spec_id == this.goodsSkuDetail.goods_spec_format[i].value[j].spec_id) {
                        this.goodsSkuDetail.goods_spec_format[i].value[j].selected = false
                    }
                }
            }

            goodsSkuInfo({
                sku_id: this.skuId
            }).then(res => {
                let data = res.data
                if (data != null) {
                    data.sku_images = data.sku_images.split(",")
                    this.picZoomUrl = data.sku_images[0]
                    this.playerOptions.poster = img(data.sku_image)
                    if (data.sku_images == "") {
                        data.sku_images = this.master_img;
                        this.picZoomUrl = data.sku_images[0]
                        this.playerOptions.poster = img(data.sku_image)
                    } else {
                        data.sku_images = data.sku_images.concat(this.master_img);
                    }
                    // 当前商品SKU规格
                    if (data.sku_spec_format) data.sku_spec_format = JSON.parse(data.sku_spec_format)

                    // 商品SKU格式
                    if (data.goods_spec_format) data.goods_spec_format = JSON.parse(data.goods_spec_format)

                    if (data.goods_attr_format) data.goods_attr_format = JSON.parse(data.goods_attr_format)
                    this.keyInput(true)

                    // 限时折扣
                    if (data.promotion_type == 1) {
                        this.discountTimeMachine = {
                            currentTime: res.timestamp,
                            startTime: res.timestamp,
                            endTime: data.end_time
                        }
                    }
                    this.specBtnRepeat = false
                    Object.assign(this.goodsSkuDetail, data)
                } else {
                    this.$router.push("/")
                }
            })
        },
        changeNum(tag) {
            if (this.goodsSkuDetail.stock == 0) return

            var stock = this.goodsSkuDetail.stock
            var min = 1

            if (tag == "+") {
                // 加
                if (this.number < stock) {
                    this.number++
                } else {
                    return
                }
            } else if (tag == "-") {
                // 减
                if (this.number > min) {
                    this.number -= 1
                } else {
                    return
                }
            }
        },
        //输入数量
        keyInput() {
            var stock = this.goodsSkuDetail.stock
                // 库存为0
            if (this.goodsSkuDetail.stock == 0) {
                this.number = 0
                return
            }
            // 防止空
            if (this.number == 0 || this.number == '') this.number = 1

            var re = /^\d+$/
            if (re.test(parseInt(this.number))) {
                if (this.number > stock) {
                    this.number = stock
                }
                this.number = parseInt(this.number)
            } else {
                this.number = 1
            }
        },
        // 播放回调
        onPlayerPlay(player) {},
        // 暂停回调
        onPlayerPause(player) {},
        // 视频播完回调
        onPlayerEnded(player) {},
        // DOM元素上的readyState更改导致播放停止
        onPlayerWaiting(player) {},
        // 已开始播放回调
        onPlayerPlaying(player) {},
        // 当播放器在当前播放位置下载数据时触发
        onPlayerLoadeddata(player) {},
        // 当前播放位置发生变化时触发。
        onPlayerTimeupdate(player) {},
        //媒体的readyState为HAVE_FUTURE_DATA或更高
        onPlayerCanplay(player) {},
        //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
        onPlayerCanplaythrough(player) {},
        //播放状态改变回调
        playerStateChanged(playerCurrentState) {},
        //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
        playerReadied(player) {},
        // 提交订单
        joinOrder(skuGoods, type) {
            // 纠正数量
            // 只有直接下单显示库存
            if (type == 1) {
                if (skuGoods.stock == 0) {
                    this.$message({
                        message: "商品已售罄",
                        type: "warning"
                    })
                    return
                }

                if (skuGoods.buyed_num == 0) {
                    this.$message({
                        message: "购买数量不能为0",
                        type: "warning"
                    })
                    return
                }

                if (skuGoods.buyed_num > skuGoods.stock) {
                    this.$message({
                        message: "购买数量不能大于库存量",
                        type: "warning"
                    })
                    return
                }
            }

            // if (this.btnSwitch) return
            // this.btnSwitch = true

            this.$store
                .dispatch("cart/add_to_cart", {
                    // site_id: skuGoods.site_id,
                    sku_id: skuGoods.sku_id,
                    num: skuGoods.buyed_num
                })
                .then(res => {
                    var data = res.data
                        // if (data > 0) {
                        //     this.$message({
                        //         message: "加入购物车成功",
                        //         type: "success"
                        //     })
                        // }
                        // this.btnSwitch = false
                })
                .catch(err => {
                    if (err.message == '您尚未登录，请先进行登录') {
                        this.$router.push('/login');
                    } else {
                        this.$message.error(err.message)
                    }
                    // this.btnSwitch = false
                })

            return true
        },
        // 加入购物车
        joinCart(skuGoods) {
            // 纠正数量
            if (skuGoods.stock == 0) {
                this.$message({
                    message: "商品已售罄",
                    type: "warning"
                })
                return
            }

            if (skuGoods.buyed_num == 0) {
                this.$message({
                    message: "购买数量不能为0",
                    type: "warning"
                })
                return
            }

            if (skuGoods.buyed_num > skuGoods.stock) {
                this.$message({
                    message: "购买数量不能大于库存量",
                    type: "warning"
                })
                return
            }

            // if (this.btnSwitch) return
            // this.btnSwitch = true

            this.$store
                .dispatch("cart/add_to_cart", {
                    // site_id: skuGoods.site_id,
                    sku_id: skuGoods.sku_id,
                    num: skuGoods.buyed_num
                })
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                    // this.btnSwitch = false
                })
                .catch(err => {
                    if (err.message == '您尚未登录，请先进行登录') {
                        this.$router.push('/login');
                    } else {
                        this.$message.error(err.message)
                    }
                    // this.btnSwitch = false
                })

            return true
        },
        // 立即购买
        buyNow() {

            if (this.goodsSkuDetail.stock == 0) {
                this.$message({
                    message: "商品已售罄",
                    type: "warning"
                })
                return
            }

            if (this.number.length == 0 || this.number == 0) {
                this.$message({
                    message: "购买数量不能为0",
                    type: "warning"
                })
                return
            }

            if (parseInt(this.number) + parseInt(this.goodsSkuDetail.purchased_num) > this.goodsSkuDetail.max_buy && this.goodsSkuDetail
                .max_buy != 0) {
                let _newNum = parseInt(this.goodsSkuDetail.max_buy) - parseInt(this.goodsSkuDetail.purchased_num)
                this.$message({
                    message: '商品限购' + this.goodsSkuDetail.max_buy + '件，现在已经购买' + this.goodsSkuDetail.purchased_num + '件,还能购买' +
                        _newNum + '件',
                    type: "warning"
                })
                return
            }

            var data = {
                sku_id: this.skuId,
                num: this.number
            }

            this.$store.dispatch("order/setOrderCreateData", data)
            this.$router.push({
                path: "/payment"
            })
        },
        countDownS_cb() {},
        countDownE_cb() {
            this.discountText = "活动已结束"
        },
        //是否关注
        // isFollow() {
        // 	shopIsSubscribe({
        // 		site_id: this.goodsSkuDetail.site_id
        // 	}).then(res => {
        // 		if (res.code == 0) {
        // 			this.hasFollow = res.data
        // 		}
        // 	})
        // },
        // follow() {
        // 	if (this.hasFollow) {
        // 		deleteShopSubscribe({
        // 			site_id: this.goodsSkuDetail.site_id
        // 		}).then(res => {
        // 			if (res.code == 0 && res.data) {
        // 				this.hasFollow = !this.hasFollow
        // 				this.$message({
        // 					message: "取消成功",
        // 					type: "success"
        // 				})
        // 			}
        // 		})
        // 	} else {
        // 		addShopSubscribe({
        // 			site_id: this.goodsSkuDetail.site_id
        // 		}).then(res => {
        // 			if (res.code == 0 && res.data) {
        // 				this.hasFollow = !this.hasFollow
        // 				this.$message({
        // 					message: "关注成功",
        // 					type: "success"
        // 				})
        // 			}
        // 		})
        // 	}
        // },
        // 图片加载失败
        imageErrorSpec(index) {
            this.goodsSkuDetail.sku_images[index] = this.defaultGoodsImage
            this.picZoomUrl = this.defaultGoodsImage
        },
        /**
         * 获取地址
         * @param {Object} type
         * @param {Object} item
         * @param {Object} first 是否第一次
         */
        getAddress(type, item, first, callback) {
            let pid = 0
            switch (type) {
                case 'province':
                    //加载省
                    pid = 0
                    break
                case 'city':
                    //加载市
                    if (item) {
                        this.provinceId = item.id
                    }
                    pid = this.provinceId
                    this.cityArr = {}
                    this.districtArr = {}
                    break
                case 'district':
                    //加载区县
                    if (item) this.cityId = item.id
                    pid = this.cityId
                    this.districtArr = {}
                    break
            }
            if (item) {
                if (item.level <= 2) {
                    let len = item.level;
                    for (let i = len; i <= 3; i++) {
                        delete this.selectedAddress['level_' + i];
                    }
                }
                this.selectedAddress['level_' + item.level] = item;
            }

            if (!first) this.$store.commit("app/SET_LOCATION_REGION", this.selectedAddress)
            this.$forceUpdate();
            if (type == 'community') {
                this.hideRegion = true;
                setTimeout(() => {
                    this.hideRegion = false;
                }, 10);
                return;
            }

            getArea({
                    pid: pid
                })
                .then(res => {
                    const {
                        code,
                        data
                    } = res;
                    if (data) {
                        switch (type) {
                            case 'province':
                                //加载省
                                this.provinceArr = data
                                break
                            case 'city':
                                //加载市
                                this.cityArr = data
                                break
                            case 'district':
                                //加载区县
                                this.districtArr = data
                                break
                        }
                        this.currTabAddres = type

                        if (callback) callback();
                    }
                })
                .catch(err => {})
        }
    }
}
