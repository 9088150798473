<template>
    <div class="goods-detail">
        <bread-nav class="bread-nav"></bread-nav>
        <div class="goods-top">
            <div class="goods-img">
                <div class="goods-show">
                    <div class="el-image">
                        <video class="video1" v-if="goods.video_url" ref="movie" :src="'https://jzwy.yfbbc.com//' + goods.video_url"
						 style="object-fit: cover;width: ;" controls></video>
						<!-- <video  id="myVue" :class="istrue?'xiao':'da'" @click="istrue=!istrue" v-if="goods.video_url" ref="movie" :src="'https://jzwy.yfbbc.com//' + goods.video_url" style="object-fit: cover;width: ;" controls></video> -->

                        <!-- <i v-show="isplay" class="el-icon-video-play" @click="handlePlay"></i>
                        <i v-show="!isplay" class="el-icon-video-pause" @click="handlePause"></i> -->
                        <img class="img1" style="width: 100%; height: 100%; object-fit: cover" :src="$img(picZoomUrl)" alt="" /><!--$imgUrlTo-->
                    </div>
                    <span class="goods-prev"></span>
                    <span class="goods-next"></span>
                </div>
                <div style="width: 80px; overflow: hidden">
                    <div class="goods-slide">
                        <!-- <el-button style="padding: 10px; margin-bottom: 10px" v-if="goods.video_url" type="primary" @click="lookVideo(item)"><a style="color: #fff" :href="'https://jzwy.yfbbc.com//' + goods.video_url" target="_blank">查看视频</a></el-button> -->
                        <div class="el-image video2" @click="showToIndex(index)" :class="`${showIndex == index ? 'act' : ''}`" v-for="(item, index) in picImages" :key="this">
                            <img class="img2" style="width: 100%; height: 100%; object-fit: cover" :src="$img(picImages[index])" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="goods-msg">
                <h2>{{ goods.goods_name }}</h2>
                <p class="goods-introduce">{{ goods.introduction }}</p>
                <div class="goods-prices">
                    <span
                        >零售价格：<i>{{ "￥"+goods.price }}</i></span
                    >
                    <span
                        >您的价格：
                        <i v-if="logined && goods.goods_fixedprice_state==1">￥{{ goods.goods_fixedprice }}</i>
						            <i v-if="logined && goods.goods_fixedprice_state==0" v-text="getMemberPrice(goods)"></i>
                        <i v-if="!logined" @click="goTo()">请登录</i></span
                    >
                </div>
                <div class="goods-info">
                    <table class="goods_details">
                        <tr>
                            <td>商品货号：{{ goods.goods_no }}</td>
                            <td>商品品牌：{{ goods.brand_name }}</td>
                        </tr>
                        <tr>
                            <td>整箱入数：{{ goods.total }}</td>
                            <td>商品产地：{{ goods.origin }}</td>
                        </tr>
                        <tr>
                            <td>内包装数：{{ goods.nei_number }}</td>
                            <td>产品规格：{{ goods.guige }}</td>
                        </tr>
                        <tr>
                            <td>整箱体积：{{ goods.box_v }}</td>
                            <td>产品材质：{{ goods.goods_cz }}</td>
                        </tr>
                    </table>
                </div>
                <div class="goods-sku">
                    <el-table :data="skuData" style="width: 100%" class="content1">
                        <el-table-column prop="sku_no" label="国际条码"> </el-table-column>
                        <el-table-column prop="color_name" label="产品颜色"> </el-table-column>
                        <!-- <el-table-column prop="sku_name" label="规格名称"> </el-table-column> -->
                        <el-table-column prop="stock" label="商品库存">
                            <template slot-scope="scope">
                                <span>{{ logined ? scope.row.stock : "仅会员可见" }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="订购数量" width="150">
                            <template slot-scope="scope">
                                <el-input-number size="mini" :step="1" :min="0" @change="numChange()" v-model="scope.row.buyed_num"></el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="goods-control" :data="skuData">
                    <el-button round @click="actionOrder()">提交订单</el-button>
                    <el-button round @click="addCart()">加入购物车</el-button>

                    <span class="collect-btn" @click="editCollection">
                        <img v-if="goods.is_shou_chang == 1" :src="require('img/alibaba/collect-base.png')" />
                        <img  v-if="goods.is_shou_chang == 0" :src="require('img/alibaba/collect-goods.png')"  />收藏</span>
                </div>
            </div>
        </div>

        <!-- goods下部 -->
        <div class="goods-bot">
            <el-radio-group v-model="tabCheckValue" style="width: 100%;
    background-color: #f7f7f7;
}">
                <el-radio-button :label="item" :key="this" v-for="(item, index) in tabCheck">{{ item }}</el-radio-button>
            </el-radio-group>
            <div class="goods-con">
                <div class="goods-detail-img" v-if="tabCheckValue == tabCheck[0]">
                    <div v-for="(item, index) in goods_attr_format" :key="index" style="text-align: left">
                        {{item.attr_name}}:{{item.attr_value_name}}
                    </div>
                    <div v-html="goods.goods_content" class="photo"></div>
                </div>
                <div class="goods-detail-goods" style="padding-top: 20px" v-if="tabCheckValue == tabCheck[1]">
                    <goods-list :data="goodsSeemList" :pageSize="15" :showpage="false"></goods-list>
                </div>
                <div class="goods-detail-commend" style="padding-top: 20px" v-if="tabCheckValue == tabCheck[2]">
                    <goods-list :data="goodsSeemGrandList" :pageSize="15" :showpage="false"></goods-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PicZoom from "vue-piczoom"
import detail from "./detail"
// import GoodsRecommend from "@/components/GoodsRecommend"
// import servicerMessage from "@/components/message/servicerMessage";
import GoodsList from "@/layout/components/goods-list"
import BreadNav from "@/layout/components/bread-nav"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"
export default {
    name: "detail",
    components: {
        PicZoom,
        // GoodsRecommend,
        // servicerMessage,
        BreadNav,
        Swiper,
        SwiperSlide,
        GoodsList
    },

    data() {
        const _this = this
        return {
            swiperOptions: {
                navigation: {
                    nextEl: ".goods-prev",
                    prevEl: ".goods-next"
                },
                direction: "vertical",
                slidesPerView: 5,
                spaceBetween: 10,
                // shouchang:'',
                on: {
                    slideChange() {
                        _this.picZoomUrl = _this.test[this.activeIndex]
                    },
                    click() {
                        console.log(_this.$refs.swiper)
                        _this.$refs.swiper.$swiper.slideTo(this.clickedIndex, 0, true)
                    }
                }
            },
            loading: false,
            ispaly: true
        }
    },
    mixins: [detail],
    methods: {
        getMemberPrice (goods) {
            if (goods.member_price) {
                return '￥' + goods.member_price
            }
            let m1 = (goods.cost_price*(goods.consume_discount/100)).toFixed(2)
            console.log("价格公式",goods.price, goods.consume_discount, m1);
            if (!isNaN(m1) && goods.is_consume_discount) {
                return '￥' + m1;
            }
            return '￥' + goods.price
        },
        // change(currentIndex) {
        //     if (this.cm[currentIndex].type != "png") {
        //         this.$refs.video[0].pause()
        //     }
        // }
    }
    // mounted(){
    //     this.$refs.movie.addEventListener("play",this.handlePlay);
    //     this.$refs.movie.addEventListener("pause",this.handlePause);
    // }
}
</script>



<style lang="scss">
@import "./detail.scss";
.el-radio-button__inner{
	background-color: #F7F7F7;
}
.goods-con {
    width: 100%;
}
.goods-detail-img {
    text-align: center;
}
.goods_details td {
    //padding-right: 160px;
    font-size: 15px;
    color: #666;
    width:285px;
}
.content1 {
    width: 610px !important;
}
.img2:hover video1 {
    display: none;
}
.video1 {
    margin: 0 auto;
    width: 80%;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 100px;
    z-index: 100;
}
.video1:hover{
	width: 100%;
	height: 100%;
}
.goods-collect {
  display: flex;
  align-items: center;
  font-size: $ns-font-size-base;
  font-weight: bold;
  color: $ns-text-color-black;

  // .goods-collect-btn {
  //   width: 15px;
  //   height: 15px;
  //   border: 1px solid $ns-text-color-gray;
  //   border-radius: 50%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-right: 5px;

  //   img {
  //     width: 11px;
  //     height: 9px;
  //   }
  // }
}
</style>
